// extracted by mini-css-extract-plugin
export var categoryLabel = "video-page-module--category-label--80e39";
export var contentDescription = "video-page-module--content-description--fb0ae";
export var contentHeader = "video-page-module--content-header--36ccc";
export var contentTitle = "video-page-module--content-title--70505";
export var contentWrapper = "video-page-module--content-wrapper--76313";
export var dateString = "video-page-module--date-string--ae9d0";
export var errorBlink = "video-page-module--error-blink--54b11";
export var videoNavigation = "video-page-module--video-navigation--1f521";
export var videoPage = "video-page-module--video-page--aaa44";
export var videoPlayer = "video-page-module--video-player--021e8";
export var videoRatio = "video-page-module--video-ratio--28fd2";
export var vlogSection = "video-page-module--vlog-section--4f1c1";